import { render, staticRenderFns } from "./ManageCategories.vue?vue&type=template&id=5fdfb432"
import script from "./ManageCategories.vue?vue&type=script&lang=js"
export * from "./ManageCategories.vue?vue&type=script&lang=js"
import style0 from "./ManageCategories.vue?vue&type=style&index=0&id=5fdfb432&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports